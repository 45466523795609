import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffSettings = () => import('./staff/settings')
const ViewStaffCashDetail = () => import('./staff/cashDetail')
const ViewStaffCheques = () => import('./staff/cheques')

Vue.use(Router)

const router =
  [
    {
      path: '/st/finances',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'FinancesStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'cashDetail',
          name: 'FinancesStaffCashDetail',
          component: ViewStaffCashDetail,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'cheques',
          name: 'FinancesStaffCheques',
          component: ViewStaffCheques,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'settings',
          name: 'FinancesStaffSettings',
          component: ViewStaffSettings,
          beforeEnter: Permisos.IS_PERSONAL,
        },
      ]
    },

    {
      path: '/cu/finances',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/finances',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
