import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffConfiguration = () => import('./staff/configuration')
const ViewStaffCrudInformation = () => import('./staff/crudInformation')
const ViewStaffReports = () => import('./staff/reports')

Vue.use(Router)

const router =
  [
    {
      path: '/st/email-marketing',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'EmailMarketingStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'configuration',
          name: 'EmailMarketingStaffConfiguration',
          component: ViewStaffConfiguration,
          beforeEnter: Permisos.IS_PERSONAL,
        }, 
        {
          path: 'crud-information',
          name: 'EmailMarketingStaffCrudInformation',
          component: ViewStaffCrudInformation,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'reports',
          name: 'EmailMarketingStaffReports',
          component: ViewStaffReports,
          beforeEnter: Permisos.IS_PERSONAL,
        },                    
      ]
    },

    {
      path: '/cu/email-marketing',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/email-marketing',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
