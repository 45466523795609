import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')

const ViewStaffCrudForms = () => import('./staff/crudForms')
const ViewStaffDetailForms = () => import('./staff/detailForms')

Vue.use(Router)

const router =
  [
    {
      path: '/st/forms',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'FormsStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'forms',
          name: 'FormsStaffCrudForms',
          component: ViewStaffCrudForms,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'details',
          name: 'FormsStaffDetailForms',                 
          component: ViewStaffDetailForms,
          beforeEnter: Permisos.IS_PERSONAL,
        },                        
      ]
    },

    {
      path: '/cu/forms',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/forms',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
