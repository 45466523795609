import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrud = () => import('./staff/crud')
const ViewStaffView = () => import('./staff/view')

Vue.use(Router)

const router =
  [
    {
      path: '/st/staff',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'StaffDashboardStaff',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'staff',
          name: 'StaffListStaff',
          component: ViewStaffCrud,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'staff/:staffID/view',
          name: 'StaffViewStaff',
          component: ViewStaffView,
          beforeEnter: Permisos.IS_PERSONAL,
        },          
      ]
    },

    {
      path: '/cu/staff',
      component: DefaultContainer,
      children: [
      
      ]
    },

    {
      path: '/su/staff',
      component: DefaultContainer,
      children: [
      
      ]
    },
  ]

export default router
