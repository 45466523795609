import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudReport = () => import('./staff/crudReport')
const ViewStaffDetailReport = () => import('./staff/detailReport')

Vue.use(Router)

const router =
    [
        {
            path: '/st/reports-audit',
            component: DefaultContainer,
            children: [
                {
                    path: '',
                    name: 'ReportsAuditStaffDashboard',
                    component: ViewStaffDashboard,
                    beforeEnter: Permisos.IS_PERSONAL,
                },        
                {
                    path: 'crud',
                    name: 'ReportsAuditStaffCrudReport',
                    component: ViewStaffCrudReport,
                    beforeEnter: Permisos.IS_PERSONAL,
                },  
                {
                    path: 'crud/customers/:customersID',
                    name: 'ReportsAuditStaffCrudReportByCustomers',
                    component: ViewStaffCrudReport,
                    beforeEnter: Permisos.IS_PERSONAL,
                },     
                {
                    path: 'detail/:reportID/view',
                    name: 'ReportsAuditStaffDetailReport',
                    component: ViewStaffDetailReport,
                    beforeEnter: Permisos.IS_PERSONAL,
                },
            ]
        },

        {
            path: '/cu/reports-audit',
            component: DefaultContainer,
            children: [

            ]
        },

        {
            path: '/su/reports-audit',
            component: DefaultContainer,
            children: [

            ]
        },
    ]

export default router
