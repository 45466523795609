import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudReport = () => import('./staff/crudReport')
const ViewStaffDetailReport = () => import('./staff/detailReport')

Vue.use(Router)

const router =
  [
    {
      path: '/st/reports-services',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'ReportsServicesStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
        {
          path: 'crud',
          name: 'ReportsServicesStaffCrudReport',
          component: ViewStaffCrudReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'crud/customers/:customersID',
          name: 'ReportsServicesStaffCrudReportByCustomers',
          component: ViewStaffCrudReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },                
        {
          path: 'detail/:reportID/view',
          name: 'ReportsServicesStaffDetailReport',
          component: ViewStaffDetailReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },
      ]
    },

    {
      path: '/cu/reports-services',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/reports-services',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
