import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewCustomerDashboard = () => import('./customer/dashboard')
const ViewSupplierDashboard = () => import('./supplier/dashboard')

Vue.use(Router)

const router =
  [
    {
      path: '/st',
      component: DefaultContainer,
      children: [
        {
          path: 'dashboard',
          name: 'DashboardStaff',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
      ]
    },

    {
      path: '/cu',
      component: DefaultContainer,
      children: [
        {
          path: 'dashboard',
          name: 'DashboardCustomer',
          component: ViewCustomerDashboard,
          beforeEnter: Permisos.IS_CLIENTE,
        },
      ]
    },

    {
      path: '/su',
      component: DefaultContainer,
      children: [
        {
          path: 'dashboard',
          name: 'DashboardSupplier',
          component: ViewSupplierDashboard,
          beforeEnter: Permisos.IS_PROVEEDOR,
        },
      ]
    },
  ]

export default router
