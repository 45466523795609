import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudCategory = () => import('./staff/crudCategory')
const ViewStaffCrudServices = () => import('./staff/crudServices')
const ViewStaffCrudServicesCustomers = () => import('./staff/crudServicesCustomers')
const ViewStaffDisplayServicesCustomers = () => import('./staff/displayServicesCustomers')

Vue.use(Router)

const router =
  [
    {
      path: '/st/services',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'ServicesStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
        {
          path: 'services',
          name: 'ServicesStaffCrudServices',
          component: ViewStaffCrudServices,
          beforeEnter: Permisos.IS_PERSONAL,
        },   
        {
          path: 'services-category',
          name: 'ServicesStaffCrudCategory',
          component: ViewStaffCrudCategory,
          beforeEnter: Permisos.IS_PERSONAL,
        },   
        {
          path: 'services-customers',
          name: 'ServicesStaffCrudServicesCustomers',
          component: ViewStaffCrudServicesCustomers,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'display-services-customers',
          name: 'ServicesStaffDisplayServicesCustomers',
          component: ViewStaffDisplayServicesCustomers,
          beforeEnter: Permisos.IS_PERSONAL,
        }                       
      ]
    },

    {
      path: '/cu/services',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/services',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
