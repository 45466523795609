import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffRemits = () => import('./staff/remits')
const ViewStaffPending = () => import('./staff/pending')

Vue.use(Router)

const router =
  [
    {
      path: '/st/remits',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'RemitsStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'remits',
          name: 'RemitsStaffRemits',
          component: ViewStaffRemits,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'remits/generate/orders/:ordersID',
          name: 'RemitsStaffRemitsGenerateByOrders',
          component: ViewStaffRemits,
          beforeEnter: Permisos.IS_PERSONAL,
        },          
        {
          path: 'penidng',
          name: 'RemitsStaffPending',
          component: ViewStaffPending,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
      ]
    },

    {
      path: '/cu/remits',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/remits',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
