<template>
  <router-view></router-view>
</template>

<script>
  import Session from '@/handler/session'
  import {EventBus} from '@/handler/event-bus'

  export default {
    name: 'app',
    data () {
      return {
        favicon: '',
        proyectName: '',
        primaryColor: '',
      }
    },
    created () {
      EventBus.$on('Settings:settings', data => {
        this.setup()
      })
    },
    mounted() {
      this.setup()
    },
    methods: {
      setup () {
        if(Session.getSession().settings) {
          this.favicon = Session.getSession().settings.favicon
          this.proyectName = Session.getSession().settings.proyect_name
          this.primaryColor = Session.getSession().settings.color_primary

          this.changeFavicon()
          this.changeTitle()
          this.createCSS()
        }
      },
      changeFavicon() {
        var link = null
        link = document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = this.favicon
        document.getElementsByTagName('head')[0].appendChild(link)
      },
      changeTitle() {
        document.title = this.proyectName

        var metas = document.getElementsByTagName("meta");

        for(var i=0;i<metas.length;i++){
          if(metas[i].getAttribute("name")=="apple-mobile-web-app-title") {
              metas[i].setAttribute("content",this.proyectName);
          }                    
        }
      },
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""
        hoja.innerHTML = hoja.innerHTML  + ".primary-color { color: " + this.primaryColor + "; }"
        hoja.innerHTML = hoja.innerHTML  + ".primary-color:hover {color: " + this.primaryColor + "; filter:brightness(0.8);}"
        hoja.innerHTML = hoja.innerHTML  + ".primary-background { background: " + this.primaryColor + "; border-color: " + this.primaryColor + "; color: white; }"
        hoja.innerHTML = hoja.innerHTML  + ".primary-background:hover { background: " + this.primaryColor + "; border-color: " + this.primaryColor + "; color: white; filter:brightness(0.8); }";
        document.body.appendChild(hoja);
      }
    }
  }
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';

  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import 'assets/scss/simple-line-icons.scss';
  //@import '~simple-line-icons/scss/simple-line-icons.scss';

  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';

  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';

  // Import Main styles for this application
  @import 'assets/scss/style';

  //  Import style for this notifications
  @import '~vue-awesome-notifications/dist/styles/style.scss';

  // Import style for this loading
  @import '~vue-loading-overlay/dist/vue-loading.css';

  //  Import style for this calendar
  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/daygrid/main.css';

  //  Import style for this select
  @import 'assets/css/vue-select.css';

  @import 'assets/css/kanban.css';
</style>
