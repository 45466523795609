import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffConfiguration = () => import('./staff/configuration')
const ViewStaffCrudInformation = () => import('./staff/crudInformation')
const ViewStaffReports = () => import('./staff/reports')

Vue.use(Router)

const router =
  [
    {
      path: '/st/google-adwords',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'GoogleAdwordsStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'configuration',
          name: 'GoogleAdwordsStaffConfiguration',
          component: ViewStaffConfiguration,
          beforeEnter: Permisos.IS_PERSONAL,
        }, 
        {
          path: 'crud-information',
          name: 'GoogleAdwordsStaffCrudInformation',
          component: ViewStaffCrudInformation,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'reports',
          name: 'GoogleAdwordsStaffReports',
          component: ViewStaffReports,
          beforeEnter: Permisos.IS_PERSONAL,
        },                    
      ]
    },

    {
      path: '/cu/google-adwords',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/google-adwords',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
