import Session from '@/handler/session'
import Profiles from '@/config/profiles'
import router from '@/router'
import Helper from '@/handler/helper'

// Esta Ok
const isAutenticado = (to, from, next) => {
  if (Session.getSession().settings.status && Session.getSession().auth) {
    if(Session.getSession().auth) {
      if(Session.getSession().lastPage) {
        router.push({ path: Session.getSession().lastPage.slice(2,-1) }).catch(error => {})
        Session.removeSessionLastPage()
      }
      else {
        if(Session.getSession().auth.user.profile_id == Profiles.SUPERADMIN) {
          router.push({ name: 'SA_ModulesConfigurate' })
        } else {
          if(Session.getSession().auth.user.profile_id == Profiles.PERSONAL &&
              Session.getSession().auth.user.rol_admin) {
            router.push({ name: 'A_StaffConfigurate' })
          } else {
            if(Session.getSession().auth.user.profile_id == Profiles.PERSONAL) {
              router.push({ name: 'DashboardStaff' })
            }

            if(Session.getSession().auth.user.profile_id == Profiles.CLIENTE) {
              router.push({ name: 'DashboardCustomer' })
            }

            if(Session.getSession().auth.user.profile_id == Profiles.PROVEEDOR) {
              router.push({ name: 'DashboardSupplier' })
            }
          }
        }
      }
    }
  } else {
    router.push({ name: 'Init' })
  }
}

// Esta Ok
const isNotAutenticado = (to, from, next) => {
  if (Session.getSession().settings.status && !Session.getSession().auth) {
    next()
  } else {
    isAutenticado()
  }
}

// Esta Ok
const isNotConfigurado = (to, from, next) => {
  if (!Session.getSession().settings.status) {
    next()
  } else {
    router.push({ name: 'Init' })
  }
}

// Esta OK
const isSuperadmin = (to, from, next) => {
  if(Session.getSession().auth) {
    var perfil_id = parseInt(Session.getSession().auth.user.profile_id);
    if (Session.getSession().auth && perfil_id == Profiles.SUPERADMIN) {
      next()
    } else {
      router.push({ name: 'Error404' })
    }
  } else {
    router.push({ name: 'Init' })
  }
}

// Esta OK
const isAdmin = (to, from, next) => {
  if(Session.getSession().auth) {
    var perfil_id = parseInt(Session.getSession().auth.user.profile_id);
    if (Session.getSession().auth && perfil_id == Profiles.PERSONAL &&
        Session.getSession().auth.user.rol_admin) {
      next()
    } else {
      router.push({ name: 'Error404' })
    }
  } else {
    router.push({ name: 'Init' })
  }
}

// Esta OK
const isPersonal = (to, from, next) => {
  if(Session.getSession().auth) {
    var perfil_id = parseInt(Session.getSession().auth.user.profile_id);
    if (Session.getSession().auth && perfil_id == Profiles.PERSONAL &&
    !Session.getSession().auth.user.rol_admin) {
      if (Helper.hasAccessModule(to.path)) {
        next()
      } else {
        router.push({ name: 'Error404' })
      }
    } else {
      router.push({ name: 'Error404' })
    }
  } else {
    router.push({ name: 'Init' })
  }
}

// Esta OK
const isCliente = (to, from, next) => {
  if(Session.getSession().auth) {
    var perfil_id = parseInt(Session.getSession().auth.user.profile_id);
    if (Session.getSession().auth && perfil_id == Profiles.CLIENTE) {
      if (Helper.hasAccessModule(to.path)) {
        next()
      } else {
        router.push({ name: 'Error404' })
      }
    } else {
      router.push({ name: 'Error404' })
    }
  } else {
    router.push({ name: 'Init' })
  }
}

// Esta OK
const isProveedor = (to, from, next) => {
  if(Session.getSession().auth) {
    var perfil_id = parseInt(Session.getSession().auth.user.profile_id);
    if (Session.getSession().auth && perfil_id == Profiles.PROVEEDOR) {
      if (Helper.hasAccessModule(to.path)) {
        next()
      } else {
        router.push({ name: 'Error404' })
      }
    } else {
      router.push({ name: 'Error404' })
    }
  } else {
    router.push({ name: 'Init' })
  }
}

export default {
  IS_AUTENTICADO: isAutenticado, // Esta OK
  IS_NOT_AUTENTICADO: isNotAutenticado, // Esta OK
  IS_NOT_CONFIGURADO: isNotConfigurado, // Esta OK
  IS_SUPERADMIN: isSuperadmin, // Esta OK
  IS_ADMIN: isAdmin, // Esta OK
  IS_PERSONAL: isPersonal, // Esta OK
  IS_CLIENTE: isCliente, // Esta OK
  IS_PROVEEDOR: isProveedor, // Esta OK
}
