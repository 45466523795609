import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudReport = () => import('./staff/crudReport')
const ViewStaffCrudReportSeller = () => import('./staff/crudReportSeller')
const ViewStaffDetailReport = () => import('./staff/detailReport')

Vue.use(Router)

const router =
  [
    {
      path: '/st/reports-general',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'ReportsGeneralStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'crud',
          name: 'ReportsGeneralStaffCrudReport',
          component: ViewStaffCrudReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'crud-seller',
          name: 'ReportsGeneralStaffCrudReportSeller',
          component: ViewStaffCrudReportSeller,
          beforeEnter: Permisos.IS_PERSONAL,
        },         
        {
          path: 'detail/:reportID/view',
          name: 'ReportsGeneralStaffDetailReport',
          component: ViewStaffDetailReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },
      ]
    },

    {
      path: '/cu/reports-general',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/reports-general',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
