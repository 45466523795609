import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrud = () => import('./staff/crud')
const ViewStaffView = () => import('./staff/view')

Vue.use(Router)

const router =
  [
    {
      path: '/st/suppliers',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'SuppliersDashboardStaff',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'suppliers',
          name: 'SuppliersListStaff',
          component: ViewStaffCrud,
          beforeEnter: Permisos.IS_PERSONAL,
        },       
        {
          path: 'suppliers/:suppliersID/view',
          name: 'SuppliersViewStaff',
          component: ViewStaffView,
          beforeEnter: Permisos.IS_PERSONAL,
        },
      ]
    },

    {
      path: '/cu/suppliers',
      component: DefaultContainer,
      children: [
        
      ]
    },  

    
    {
      path: '/su/suppliers',
      component: DefaultContainer,
      children: [
        
      ]
    },  
  ]

export default router
