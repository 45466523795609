import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')

const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffConfiguration = () => import('./staff/configuration')

Vue.use(Router)

const router =
  [
    {
      path: '/st/payments',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'PaymentsStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'configurations',
          name: 'PaymentsStaffConfigurations',
          component: ViewStaffConfiguration,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
      ]
    },

    {
      path: '/cu/payments',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/payments',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
