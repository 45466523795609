import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudCourses = () => import('./staff/crudCourses')
const ViewStaffCrudCategories = () => import('./staff/crudCategories')

Vue.use(Router)

const router =
  [
    {
      path: '/st/courses',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'CoursesStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'courses',
          name: 'CoursesStaffCrudCourses',
          component: ViewStaffCrudCourses,
          beforeEnter: Permisos.IS_PERSONAL,
        },                
        {
          path: 'categories',
          name: 'CoursesStaffCrudCategories',
          component: ViewStaffCrudCategories,
          beforeEnter: Permisos.IS_PERSONAL,
        },                              
      ]
    },

    {
      path: '/cu/courses',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/courses',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
