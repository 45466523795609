import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewIndex = () => import('./index')

Vue.use(Router)

const router =
  [
    {
      path: '/sa/roles',
      component: DefaultContainer,
      children: [
        {
          path: 'configurate',
          name: 'SA_RolesConfigurate',
          component: ViewIndex,
          beforeEnter: Permisos.IS_SUPERADMIN,
        },
      ]
    },
  ]

export default router
