import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudPHPInterfaces = () => import('./staff/crudPHPInterfaces')
const ViewStaffCrudPHPCron = () => import('./staff/crudPHPCron')
const ViewStaffCrudPHPCallback = () => import('./staff/crudPHPCallback')
const ViewStaffCrudPHPCode = () => import('./staff/crudPHPCode')

Vue.use(Router)

const router =
  [
    {
      path: '/st/php',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'PHPStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },   
        {
          path: 'interfaces',
          name: 'PHPStaffCrudPHPInterfaces',
          component: ViewStaffCrudPHPInterfaces,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'cron',
          name: 'PHPStaffCrudPHPCron',
          component: ViewStaffCrudPHPCron,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'callback',
          name: 'PHPStaffCrudPHPCallback',
          component: ViewStaffCrudPHPCallback,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
        {
          path: 'code',
          name: 'PHPStaffCrudPHPCode',
          component: ViewStaffCrudPHPCode,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
      ]
    },

    {
      path: '/cu/php',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/php',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
