import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudInstancesServer = () => import('./staff/crudInstancesServer')
const ViewStaffCrudInstancesSystem = () => import('./staff/crudInstancesSystem')
const ViewStaffCrudInstancesSystemOthers = () => import('./staff/crudInstancesSystemOthers')
const ViewStaffCrudInstancesCategories = () => import('./staff/crudInstancesCategories')

Vue.use(Router)

const router =
  [
    {
      path: '/st/instances',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'InstancesStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'server',
          name: 'InstancesStaffCrudInstancesServer',
          component: ViewStaffCrudInstancesServer,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'system',
          name: 'InstancesStaffCrudInstancesSystem',
          component: ViewStaffCrudInstancesSystem,
          beforeEnter: Permisos.IS_PERSONAL,
        },         
        {
          path: 'others-system',
          name: 'InstancesStaffCrudInstancesSystemOthers',
          component: ViewStaffCrudInstancesSystemOthers,
          beforeEnter: Permisos.IS_PERSONAL,
        },                 
        {
          path: 'system',
          name: 'InstancesStaffCrudInstancesCategories',
          component: ViewStaffCrudInstancesCategories,
          beforeEnter: Permisos.IS_PERSONAL,
        },                  
      ]
    },

    {
      path: '/cu/instances',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/instances',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
