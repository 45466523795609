import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffSliders = () => import('./staff/elements/crudSliders')
const ViewStaffPopups = () => import('./staff/elements/crudPopups')
const ViewStaffDownloads = () => import('./staff/elements/crudDownloads')
const ViewStaffPaymentMethods = () => import('./staff/elements/crudPaymentMethods')
const ViewStaffPages = () => import('./staff/crudPages')
const ViewStaffPagesBlocks =() => import('./staff/crudPagesBlocksContents')
const ViewStaffSettings =() => import('./staff/crudSettings')
const ViewStaffBlocks =() => import('./staff/crudBlocks')
const ViewStaffStoreDeliveries =() => import('./staff/crudStoreDeliveries')
const ViewStaffStoreDiscounts =() => import('./staff/crudStoreDiscounts')
const ViewStaffPortabilityTemplate =() => import('./staff/crudPortabilityTemplate')
const ViewStaffTemplate =() => import('./staff/crudTemplate')

Vue.use(Router)

const router =
  [
    {
      path: '/st/contents',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'ContentsSttaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },   
        {
          path: 'sliders',
          name: 'ContentsSttaffSliders',
          component: ViewStaffSliders,
          beforeEnter: Permisos.IS_PERSONAL,
        },   
        {
          path: 'popups',
          name: 'ContentsSttaffPopups',
          component: ViewStaffPopups,
          beforeEnter: Permisos.IS_PERSONAL,
        },   
        {
          path: 'downloads',
          name: 'ContentsSttaffDownloads',
          component: ViewStaffDownloads,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'payment-methods',
          name: 'ContentsSttaffPaymentMethods',
          component: ViewStaffPaymentMethods,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'pages',
          name: 'ContentsSttaffPages',
          component: ViewStaffPages,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'pages-block/:blockID',
          name: 'ContentsSttaffConfiguratePagesBlocks',
          component: ViewStaffPagesBlocks,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'settings',
          name: 'ContentsSttaffSettings',
          component: ViewStaffSettings,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'blocks',
          name: 'ContentsSttaffBlocks',
          component: ViewStaffBlocks,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
        {
          path: 'store-deliveries',
          name: 'ContentsSttaffStoreDeliveries',
          component: ViewStaffStoreDeliveries,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'store-discounts',
          name: 'ContentsSttaffStoreDiscounts',
          component: ViewStaffStoreDiscounts,
          beforeEnter: Permisos.IS_PERSONAL,
        },                
        {
          path: 'portability-template',
          name: 'ContentsStaffPortabilityTemplate',
          component: ViewStaffPortabilityTemplate,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
        {
          path: 'template',
          name: 'ContentsStaffTemplate',
          component: ViewStaffTemplate,
          beforeEnter: Permisos.IS_PERSONAL,
        },                            
      ]
    },

    {
      path: '/cu/contents',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/contents',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
