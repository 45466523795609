import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')

const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudEmployee = () => import('./staff/crudEmployee')
const ViewStaffCrudCategory = () => import('./staff/crudCategory')
const ViewStaffDetailEmployeeHours = () => import('./staff/detailEmployeeHours')

Vue.use(Router)

const router =
  [
    {
      path: '/st/employee',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'EmployeeStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
        {
          path: 'employees',
          name: 'EmployeeStaffCrudEmployee',
          component: ViewStaffCrudEmployee,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'employees/:employeeID/hours',
          name: 'EmployeeStaffDetailHours',
          component: ViewStaffDetailEmployeeHours,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'category',
          name: 'EmployeetStaffCrudCategory',
          component: ViewStaffCrudCategory,
          beforeEnter: Permisos.IS_PERSONAL,
        },                      
      ]
    },

    {
      path: '/cu/employee',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/Employee',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
