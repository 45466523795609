import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')

const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudTank = () => import('./staff/crudTank')
const ViewStaffCrudSensorsAssociateTankCustomer = () => import('./staff/crudSensorsAssociateTankCustomer')
const ViewStaffListSensorsTankCustomer = () => import('./staff/listSensorsTankCustomer')
const ViewStaffDetailSensorsTankCustomer = () => import('./staff/detailSensorsTankCustomer')
const ViewStaffCrudSensorsNotifications = () => import('./staff/crudSensorsNotifications')
const ViewStaffConfigurations = () => import('./staff/configurations')

const ViewCustomersDashboard = () => import('./customer/dashboard')
const ViewCustomerListSensorsTank = () => import('./customer/listSensorsTankCustomer')
const ViewCustomerDdetailSensorsTank = () => import('./customer/detailSensorsTank')
const ViewCustomerCrudSensorsNotifications = () => import('./customer/crudSensorsNotifications')

Vue.use(Router)

const router =
  [
    {
      path: '/st/sensors',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'SensorsStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'tanks',
          name: 'SensorsStaffCrudTank',
          component: ViewStaffCrudTank,
          beforeEnter: Permisos.IS_PERSONAL,
        },              
        {
          path: 'tanks/customer',
          name: 'SensorsStaffListSensorsTankCustomer',
          component: ViewStaffListSensorsTankCustomer,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'tanks/associate/customer',
          name: 'SensorsStaffCrudSensorsAssociateTankCustomer',
          component: ViewStaffCrudSensorsAssociateTankCustomer,
          beforeEnter: Permisos.IS_PERSONAL,
        },                     
        {
          path: 'tanks/customer/:customerTankID/detail',
          name: 'SensorsStaffDetailSensorsTankCustomer',
          component: ViewStaffDetailSensorsTankCustomer,
          beforeEnter: Permisos.IS_PERSONAL,
        },            
        {
          path: 'tanks/customer/:customerTankID/notifications',
          name: 'SensorsStaffNotificationsSensorsTankCustomer',
          component: ViewStaffCrudSensorsNotifications,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
        {
          path: 'configurations',
          name: 'SensorsStaffConfigurations',
          component: ViewStaffConfigurations,
          beforeEnter: Permisos.IS_PERSONAL,
        },                                  
      ]
    },

    {
      path: '/cu/sensors',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'SensorsCustomerDashboard',
          component: ViewCustomersDashboard,
          beforeEnter: Permisos.IS_CLIENTE,
        },
        {
          path: 'tanks/customer',
          name: 'SensorsCustomerListSensorsTank',
          component: ViewCustomerListSensorsTank,
          beforeEnter: Permisos.IS_CLIENTE,
        },        
        {
          path: 'tanks/customer/:customerTankID/detail',
          name: 'SensorsCustomerDetailSensorsTank',
          component: ViewCustomerDdetailSensorsTank,
          beforeEnter: Permisos.IS_CLIENTE,
        },            
        {
          path: 'tanks/customer/:customerTankID/notifications',
          name: 'SensorsCustomerNotificationsSensorsTank',
          component: ViewCustomerCrudSensorsNotifications,
          beforeEnter: Permisos.IS_CLIENTE,
        },
      ]
    },

    {
      path: '/su/sensors',
      component: DefaultContainer,
      children: [
      
      ]
    },    
  ]

export default router
