import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')

const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudDeposits = () => import('./staff/crudDeposits')
const ViewStaffCrudDepositsOperations = () => import('./staff/crudOperations')
const ViewStaffCrudDepositsQuery = () => import('./staff/crudQuery')
const ViewStaffCrudDepositsQueryLocations = () => import('./staff/crudQueryLocations')

Vue.use(Router)

const router =
  [
    {
      path: '/st/deposits',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'DepositsStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
        {
          path: 'deposits',
          name: 'DepositsStaffCrudDeposits',
          component: ViewStaffCrudDeposits,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'deposits-query',
          name: 'DepositsStaffCrudDepositsQuery',
          component: ViewStaffCrudDepositsQuery,
          beforeEnter: Permisos.IS_PERSONAL,
        },             
        {
          path: 'deposits-operations',
          name: 'DepositsStaffCrudDepositsOperations',
          component: ViewStaffCrudDepositsOperations,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'deposits-query-locations',
          name: 'DepositsStaffCrudDepositsQueryLocations',
          component: ViewStaffCrudDepositsQueryLocations,
          beforeEnter: Permisos.IS_PERSONAL,
        },                     
      ]
    },

    {
      path: '/cu/deposits',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/deposits',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
