import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const ViewLogin = () => import('./login')
const ViewRegister = () => import('./register')
const ViewRecovery = () => import('./recovery')
const ViewClear = () => import('./clear')

Vue.use(Router)

const router =
  [
    {
      path: '/auth',
      redirect: '/auth/login',
      component: { render (c) { return c('router-view') }},
      children: [
        {
          path: 'login',
          name: 'Login',
          component: ViewLogin,
          beforeEnter: Permisos.IS_NOT_AUTENTICADO,
        },
        {
          path: 'register',
          name: 'Register',
          component: ViewRegister,
          beforeEnter: Permisos.IS_NOT_AUTENTICADO,
        },
        {
          path: 'recovery',
          name: 'Recovery',
          component: ViewRecovery,
          beforeEnter: Permisos.IS_NOT_AUTENTICADO,
        },
        {
          path: 'clear',
          name: 'Clear',
          component: ViewClear,
        },
      ]
    },
  ]

export default router
