import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')

const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffConfiguration = () => import('./staff/configuration')
const ViewStaffManualSend = () => import('./staff/manualSend')

Vue.use(Router)

const router =
  [
    {
      path: '/st/notifications',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'NotificationsStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'configurations',
          name: 'NotificationsStaffConfigurations',
          component: ViewStaffConfiguration,
          beforeEnter: Permisos.IS_PERSONAL,
        },   
        {
          path: 'manual-send',
          name: 'NotificationsStaffManualSend',
          component: ViewStaffManualSend,
          beforeEnter: Permisos.IS_PERSONAL,
        },                
      ]
    },

    {
      path: '/cu/notifications',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/notifications',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
