import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')

const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudProject = () => import('./staff/crudProject')
const ViewStaffCrudActivity = () => import('./staff/crudActivity')
const ViewStaffCrudHours = () => import('./staff/crudHours')
const ViewStaffDetailHoursProjects = () => import('./staff/detailHoursProjects')

Vue.use(Router)

const router =
  [
    {
      path: '/st/projects',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'ProjectStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
        {
          path: 'projects',
          name: 'ProjectStaffCrudProject',
          component: ViewStaffCrudProject,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'projects/:projectID/hours/detail',
          name: 'ProjectStaffDetailHoursProjects',
          component: ViewStaffDetailHoursProjects,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'activity',
          name: 'ProjectStaffCrudActivity',
          component: ViewStaffCrudActivity,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'hours',
          name: 'ProjectStaffCrudHours',
          component: ViewStaffCrudHours,
          beforeEnter: Permisos.IS_PERSONAL,
        },                       
      ]
    },

    {
      path: '/cu/projects',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/projects',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
