import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudProducts = () => import('./staff/crudProducts')
const ViewStaffCrudProductsMarketplace = () => import('./staff/crudProductsMarketplace')
const ViewStaffCrudProductsWeb = () => import('./staff/crudProductsWeb')
const ViewStaffCrudProductsCompound = () => import('./staff/crudProductsCompound')
const ViewStaffCrudCategories = () => import('./staff/crudCategories')
const ViewStaffCrudLines = () => import('./staff/crudLines')
const ViewStaffCrudColors = () => import('./staff/crudColors')
const ViewStaffCrudWaist = () => import('./staff/crudWaist')
const ViewStaffCrudContactInterested = () => import('./staff/crudContactInterested')
const ViewStaffSummaryContactSold = () => import('./staff/summaryContactSold')
const ViewStaffCrudUnitMeasurement = () => import('./staff/crudUnitMeasurement')

Vue.use(Router)

const router =
  [
    {
      path: '/st/products',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'ProductsStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'products',
          name: 'ProductsStaffCrudProducts',
          component: ViewStaffCrudProducts,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'productsMarketplace',
          name: 'ProductsStaffCrudProductsMarketplace',
          component: ViewStaffCrudProductsMarketplace,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'productsWeb',
          name: 'ProductsStaffCrudProductsWeb',
          component: ViewStaffCrudProductsWeb,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'categories',
          name: 'ProductsStaffCrudCategories',
          component: ViewStaffCrudCategories,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'lines',
          name: 'ProductsStaffCrudLines',
          component: ViewStaffCrudLines,
          beforeEnter: Permisos.IS_PERSONAL,
        },                    
        {
          path: 'colors',
          name: 'ProductsStaffCrudColors',
          component: ViewStaffCrudColors,
          beforeEnter: Permisos.IS_PERSONAL,
        },   
        {
          path: 'waist',
          name: 'ProductsStaffCrudWaist',
          component: ViewStaffCrudWaist,
          beforeEnter: Permisos.IS_PERSONAL,
        },         
        {
          path: 'marketplace/:productID',
          name: 'ProductsStaffCrudContactInterested',
          component: ViewStaffCrudContactInterested,
          beforeEnter: Permisos.IS_PERSONAL,
        },     
        {
          path: 'marketplace/report/summary',
          name: 'ProductsStaffSummaryContactSold',
          component: ViewStaffSummaryContactSold,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'productsCompound',
          name: 'ProductsStaffCrudProductsCompound',
          component: ViewStaffCrudProductsCompound,
          beforeEnter: Permisos.IS_PERSONAL,
        },          
        {
          path: 'unitMeasurement',
          name: 'ProductsStaffCrudUnitMeasurement',
          component: ViewStaffCrudUnitMeasurement,
          beforeEnter: Permisos.IS_PERSONAL,
        },                     
      ]
    },

    {
      path: '/cu/products',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/products',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
