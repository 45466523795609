import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')

Vue.use(Router)

const router =
  [
    {
      path: '/',
      name: 'Init',
      redirect: '/settings/validate',
    },
    {
      path: '/contenedor',
      name: 'Contenedor',
      component: DefaultContainer,
      beforeEnter: Permisos.IS_AUTENTICADO,
    }
  ]

export default router
