import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffConfiguration = () => import('./staff/configuration')
const ViewStaffQuery = () => import('./staff/query')
const ViewStaffLog = () =>  import('./staff/log')

Vue.use(Router)

const router =
  [
    {
      path: '/st/bcra',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'BCRAStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'configuration',
          name: 'BCRAStaffConfiguration',
          component: ViewStaffConfiguration,
          beforeEnter: Permisos.IS_PERSONAL,
        }, 
        {
          path: 'query',
          name: 'BCRAStaffQuery',
          component: ViewStaffQuery,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'query/:params',
          name: 'BCRAStaffQueryParams',
          component: ViewStaffQuery,
          beforeEnter: Permisos.IS_PERSONAL,
        },                           
        {
          path: 'log',
          name: 'BCRAStaffLog',
          component: ViewStaffLog,
          beforeEnter: Permisos.IS_PERSONAL,
        },                          
      ]
    },

    {
      path: '/cu/bcra',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/bcra',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
