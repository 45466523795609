import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudReport = () => import('./staff/crudReport')
const ViewStaffCrudReportSeller = () => import('./staff/crudReportSeller')
const ViewStaffDetailReport = () => import('./staff/detailReport')
const ViewStaffCrudSurrender = () => import('./staff/crudSurrender')
const ViewStaffCrudSurrenderSeller = () => import('./staff/crudSurrenderSeller')

Vue.use(Router)

const router =
  [
    {
      path: '/st/reports-payment',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'ReportsPaymentStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'crud',
          name: 'ReportsPaymentStaffCrudReport',
          component: ViewStaffCrudReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'crud/customers/:customersID',
          name: 'ReportsPaymentStaffCrudReportByCustomers',
          component: ViewStaffCrudReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },           
        {
          path: 'crud-seller',
          name: 'ReportsPaymentStaffCrudReportSeller',
          component: ViewStaffCrudReportSeller,
          beforeEnter: Permisos.IS_PERSONAL,
        },         
        {
          path: 'crud-seller/customers/:customersID',
          name: 'ReportsPaymentStaffCrudReportSellerByCustomers',
          component: ViewStaffCrudReportSeller,
          beforeEnter: Permisos.IS_PERSONAL,
        },          
        {
          path: 'detail/:reportID/view',
          name: 'ReportsPaymentStaffDetailReport',
          component: ViewStaffDetailReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },          
        {
          path: 'crud-surrender',
          name: 'ReportsPaymentStaffCrudSurrender',
          component: ViewStaffCrudSurrender,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'crud-surrender-seller',
          name: 'ReportsPaymentStaffCrudSurrenderSeller',
          component: ViewStaffCrudSurrenderSeller,
          beforeEnter: Permisos.IS_PERSONAL,
        },
      ]
    },

    {
      path: '/cu/reports-payment',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/reports-payment',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
