import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const ViewError403= () => import('./error403')
const ViewError404= () => import('./error404')
const ViewError500= () => import('./error500')

Vue.use(Router)

const router =
  [
    {
      path: '/error',
      component: { render (c) { return c('router-view') }},
      children: [
        {
          path: '403',
          name: 'Error403',
          component: ViewError403,
        },
        {
          path: '404',
          name: 'Error404',
          component: ViewError404,
        },
        {
          path: '500',
          name: 'Error500',
          component: ViewError500,
        },
        { 
          path: '/:pathMatch(.*)*', 
          name: 'Error404',
          component: ViewError404, 
        },
      ]
    },
  ]

export default router
