import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffViewer = () => import('./staff/viewer')

Vue.use(Router)

const router =
  [
    {
      path: '/st/activity-log',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'ActivityLogStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'viewer',
          name: 'ActivityLogStaffViewer',
          component: ViewStaffViewer,
          beforeEnter: Permisos.IS_PERSONAL,
        },                          
      ]
    },

    {
      path: '/cu/activity-log',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/activity-log',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
