import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudMessage = () => import('./staff/crudMessage')
const ViewStaffCrudConversations = () => import('./staff/crudConversations')
const ViewStaffCrudContact = () => import('./staff/crudContact')
const ViewStaffCrudLists = () => import('./staff/crudLists')
const ViewStaffCrudSettings = () => import('./staff/crudSettings')

Vue.use(Router)

const router =
  [
    {
      path: '/st/whatsapp-marketing',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'WhatsappMarketingStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'crud-message',
          name: 'WhatsappMarketingStaffCrudMessage',
          component: ViewStaffCrudMessage,
          beforeEnter: Permisos.IS_PERSONAL,
        }, 
        {
          path: 'crud-contact',
          name: 'WhatsappMarketingStaffCrudContact',
          component: ViewStaffCrudContact,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'crud-lists',
          name: 'WhatsappMarketingStaffCrudLists',
          component: ViewStaffCrudLists,
          beforeEnter: Permisos.IS_PERSONAL,
        }, 
        {
          path: 'crud-conversations',
          name: 'WhatsappMarketingStaffCrudConversations',
          component: ViewStaffCrudConversations,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'crud-settings',
          name: 'WhatsappMarketingStaffCrudSettings',
          component: ViewStaffCrudSettings,
          beforeEnter: Permisos.IS_PERSONAL,
        },                
      ]
    },

    {
      path: '/cu/whatsapp-marketing',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/whatsapp-marketing',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
