import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudReport = () => import('./staff/crudReport')
const ViewStaffCrudReportSeller = () => import('./staff/crudReportSeller')
const ViewStaffDetailReport = () => import('./staff/detailReport')
const ViewStaffQueryAnalysis = () => import('./staff/queryAnalysis')
const ViewStaffQueryAnalysisSeller = () => import('./staff/queryAnalysisSeller')

Vue.use(Router)

const router =
  [
    {
      path: '/st/reports-visit',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'ReportsVisitsStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'crud',
          name: 'ReportsVisitsStaffCrudReport',
          component: ViewStaffCrudReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'crud/customers/:customersID',
          name: 'ReportsVisitsStaffCrudReportByCustomers',
          component: ViewStaffCrudReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },          
        {
          path: 'crud-seller',
          name: 'ReportsVisitsStaffCrudReportSeller',
          component: ViewStaffCrudReportSeller,
          beforeEnter: Permisos.IS_PERSONAL,
        }, 
        {
          path: 'crud-seller/customers/:customersID',
          name: 'ReportsVisitsStaffCrudReportSellerByCustomers',
          component: ViewStaffCrudReportSeller,
          beforeEnter: Permisos.IS_PERSONAL,
        },                
        {
          path: 'detail/:reportID/view',
          name: 'ReportsVisitsStaffDetailReport',
          component: ViewStaffDetailReport,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'analysis',
          name: 'ReportsVisitsStaffQueryAnalysis',
          component: ViewStaffQueryAnalysis,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'analysis-seller',
          name: 'ReportsVisitsStaffQueryAnalysisSeller',
          component: ViewStaffQueryAnalysisSeller,
          beforeEnter: Permisos.IS_PERSONAL,
        },                
      ]
    },

    {
      path: '/cu/reports-visit',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/reports-visit',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
