import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffSettings = () => import('./staff/settings')
const ViewStaffAccountPlan = () => import('./staff/accountPlan')
const ViewStaffAccountingEntries = () => import('./staff/accountingEntries')

Vue.use(Router)

const router =
  [
    {
      path: '/st/accounting',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'AccountingStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'settings',
          name: 'AccountingStaffSettings',
          component: ViewStaffSettings,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'account-plan',
          name: 'AccountingStaffAccountPlan',
          component: ViewStaffAccountPlan,
          beforeEnter: Permisos.IS_PERSONAL,
        },                                
        {
          path: 'accounting-entries',
          name: 'AccountingStaffAccountingEntries',
          component: ViewStaffAccountingEntries,
          beforeEnter: Permisos.IS_PERSONAL,
        },     
      ]
    },

    {
      path: '/cu/accounting',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/accounting',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
