import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffReceipts = () => import('./staff/receipts')
const ViewStaffReceiptsTrashed = () => import('./staff/receiptsTrashed')

Vue.use(Router)

const router =
  [
    {
      path: '/st/receipts-erp',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'ReceiptsErpStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'receipts',
          name: 'ReceiptsErpStaffReceipts',
          component: ViewStaffReceipts,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'receipts-trashed',
          name: 'ReceiptsErpStaffReceiptsTrashed',
          component: ViewStaffReceiptsTrashed,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
      ]
    },

    {
      path: '/cu/receipts-erp',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/receipts-erp',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
