import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')

const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudSellers = () => import('./staff/crudSellers')
const ViewStaffSellersCalendar = () => import('./staff/crudSellersCalendar')
const ViewStaffSellersReports = () => import('./staff/crudSellersReports')
const ViewStaffCrudZones = () => import('./staff/crudZones')
const ViewStaffListCalendar = () => import('./staff/listCalendarSellers')
const ViewStaffListCustomers = () => import('./staff/listCustomersSellers')

Vue.use(Router)

const router =
  [
    {
      path: '/st/sellers',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'SellersStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
        {
          path: 'sellers',
          name: 'SellersStaffCrudSellers',
          component: ViewStaffCrudSellers,
          beforeEnter: Permisos.IS_PERSONAL,
        }, 
        {
          path: 'sellers/:sellersID/calendar',
          name: 'SellersStaffSellersCalendar',
          component: ViewStaffSellersCalendar,
          beforeEnter: Permisos.IS_PERSONAL,
        },     
        {
          path: 'sellers/:sellersID/reports',
          name: 'SellersStaffSellersReports',
          component: ViewStaffSellersReports,
          beforeEnter: Permisos.IS_PERSONAL,
        },           
        {
          path: 'zones',
          name: 'SellersStaffCrudZones',
          component: ViewStaffCrudZones,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'my-calendar',
          name: 'SellersStaffListCalendar',
          component: ViewStaffListCalendar,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'my-customers',
          name: 'SellersStaffListCustomers',
          component: ViewStaffListCustomers,
          beforeEnter: Permisos.IS_PERSONAL,
        },                              
      ]
    },

    {
      path: '/cu/sellers',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/sellers',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
