import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const ViewValidate = () => import('./validate')
const ViewSettings = () => import('./settings')

Vue.use(Router)

const router =
  [
    {
      path: '/settings',
      redirect: '/validate',
      component: { render (c) { return c('router-view') }},
      children: [
        {
          path: 'validate',
          name: 'Validate',
          component: ViewValidate,
        },
        {
          path: 'setup',
          name: 'Setup',
          component: ViewSettings,
          beforeEnter: Permisos.IS_NOT_CONFIGURADO
        },
      ]
    },
  ]

export default router
