var storage = {
  setValue (name, value) {        
    localStorage.setItem(window.location.origin + "_" + name, value)    
  },
  getValue (name) {        
    return localStorage.getItem(window.location.origin + "_" + name)    
  },  
  removeValue (name) {        
    localStorage.removeItem(window.location.origin + "_" + name)    
  },
}

export default storage