import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffConfiguration = () => import('./staff/configuration')

Vue.use(Router)

const router =
  [
    {
      path: '/st/bitly',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'BitlyStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'configuration',
          name: 'BitlyStaffConfiguration',
          component: ViewStaffConfiguration,
          beforeEnter: Permisos.IS_PERSONAL,
        },                          
      ]
    },

    {
      path: '/cu/bitly',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/bitly',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
