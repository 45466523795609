import Session from '@/handler/session'
import Profiles from '@/config/profiles'
import router from '@/router'

var helper = {
  hasAccessElement (data, reference) {
    var module_id = data.module_id
    var profile_id = data.profile_id
    var view_reference = data.view_reference
    var element_reference = reference

    if(!module_id || !profile_id || !view_reference || !element_reference) {
      alert('Falta implementar los permisos de la vista')
      router.push({ name: 'Error500' })
    } else {
      var status = true
      if(Session.getSession().auth) {
        var element = Session.getSession().auth.user.permissions.elements
        for (const prop in element) {
          if(element[prop].module_permission.module_id == module_id &&
             element[prop].module_permission.profile_id == profile_id &&
             element[prop].module_permission.reference == view_reference &&
             element[prop].reference == element_reference) {
            status = false
          }
        }
      }
      return status
    }
  },
  hasAccessView (data) {
    var module_id = data.module_id
    var profile_id = data.profile_id
    var reference = data.view_reference

    if(!module_id || !profile_id || !reference) {
      alert('Falta implementar los permisos de la vista')
      router.push({ name: 'Error500' })
    } else {
      var status = false
      if(Session.getSession().auth) {
        var view = Session.getSession().auth.user.permissions.items
        for (const prop in view) {
          if(view[prop].module_id == module_id &&
             view[prop].profile_id == profile_id &&
             view[prop].reference == reference) {
            status = true
          }
        }
      }
      if(!status) {
        router.push({ name: 'Error403' })
      }
    }
  },
  hasAccessModule (routerMain) {
    var status = false
    if(routerMain.substring(0,4) == '/st/' ||
       routerMain.substring(0,4) == '/cu/' ||
       routerMain.substring(0,4) == '/su/')
    {
      if(routerMain.substring(4) == 'dashboard') {
        status = true
      } else {
        if(Session.getSession().auth) {          
          var menu = Session.getSession().auth.user.permissions.menu
          for (const prop in menu) {
            if(routerMain.indexOf(this.getIdentifyRouter() + menu[prop].router)!=-1) {
              status = true
            }
          }
        }
      }
    } else {
      status = true
    }
    return status
  },
  moduleEnabled (reference) {    
    var status = false    
    if(Session.getSession().auth) {          
      //var modules = Session.getSession().auth.user.permissions.modules
      var modules = Session.getSession().auth.user.permissions.menu
      for (const prop in modules) {
        if(modules[prop].id == reference) {
          status = true
        }
      }
    }  
    return status  
  },
  getIdentifyRouter() {
    var helperRouter = ''
    if(Session.getSession().auth) {
      const profile_id = Session.getSession().auth.user.profile_id
      if(profile_id == Profiles.PERSONAL) {
        helperRouter = '/st/'
      }

      if(profile_id == Profiles.CLIENTE) {
        helperRouter = '/cu/'
      }

      if(profile_id == Profiles.PROVEEDOR) {
        helperRouter = '/su/'
      }
    }
    return helperRouter
  },
  hasParametersAccess(parameter_id) {
    var status = false
    if(Session.getSession().parameters) {      
      var parameters = Session.getSession().parameters      
      parameters.forEach(element => {
        if (element.id == parameter_id) {
          status = true
        }
      });
    }
    return status
  },
  hasAccessWidget (data, reference) {
    var module_id = data.module_id
    var profile_id = data.profile_id
    var widget_reference = reference

    if(!module_id || !profile_id || !widget_reference) {
      alert('Falta implementar los permisos del widget')
      router.push({ name: 'Error500' })
    } else {
      var status = false
      if(Session.getSession().auth) {
        var element = Session.getSession().auth.user.permissions.widget
        for (const prop in element) {
          if(element[prop].module_id == module_id &&
             element[prop].profile_id == profile_id &&
             element[prop].reference == widget_reference) {
            status = true
          }
        }
      }
      return status
    }
  },
  getCustomerId () {
    if(Session.getSession().auth) {
      return Session.getSession().auth.user.selector_session
    } else {
      return 0
    }      
  },
  getStaffId () {        
    let auth = Session.getSessionAuth()    
    if(auth) {
      return auth.user.selector_session
    } else {
      return 0
    }      
  },
  getEmployee () {    
    if(Session.getSession().auth) {
      return Session.getSession().auth.user.employee
    } else {
      return 0
    }
  },
  getSeller () {    
    if(Session.getSession().auth) {
      return Session.getSession().auth.user.seller
    } else {
      return 0
    }
  },  
  getSupplierId () {
    if(Session.getSession().auth) {
      return Session.getSession().auth.user.selector_session
    } else {
      return 0
    }      
  },  
  getPathPublic(value) {
    return window.location.origin + '/backend/public/' + value
  }
}

export default helper
