import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudPricesLists = () => import('./staff/crudPricesLists')
const ViewStaffCrudPricesListsProducts = () => import('./staff/crudPricesListsProducts')
const ViewStaffCrudPricesListsCourses = () => import('./staff/crudPricesListsCourses')
const ViewStaffCrudPricesListsServices = () => import('./staff/crudPricesListsServices')
const ViewStaffCrudCurrency = () => import('./staff/crudCurrency')

Vue.use(Router)

const router =
  [
    {
      path: '/st/prices',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'PricesStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'prices-lists',
          name: 'PricesStaffCrudPricesLists',
          component: ViewStaffCrudPricesLists,
          beforeEnter: Permisos.IS_PERSONAL,
        },                
        {
          path: 'prices-lists/:listID/products',
          name: 'PricesStaffCrudPricesListsProducts',
          component: ViewStaffCrudPricesListsProducts,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'prices-lists/:listID/courses',
          name: 'PricesStaffCrudPricesListsCourses',
          component: ViewStaffCrudPricesListsCourses,
          beforeEnter: Permisos.IS_PERSONAL,
        },     
        {
          path: 'prices-lists/:listID/services',
          name: 'PricesStaffCrudPricesListsServices',
          component: ViewStaffCrudPricesListsServices,
          beforeEnter: Permisos.IS_PERSONAL,
        },                                  
        {
          path: 'currency',
          name: 'PricesStaffCrudCurrency',
          component: ViewStaffCrudCurrency,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
      ]
    },

    {
      path: '/cu/prices',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/prices',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
